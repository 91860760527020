import {AfterViewInit, ContentChild, Directive, TemplateRef, ViewChild} from '@angular/core';
//TODO: check on NgSelect update & normalize import if possible
import {
    NgSelectComponent,
    NgOptionTemplateDirective,
    NgOptgroupTemplateDirective,
    NgLabelTemplateDirective,
    NgMultiLabelTemplateDirective,
    NgHeaderTemplateDirective,
    NgFooterTemplateDirective,
    NgNotFoundTemplateDirective,
    NgTypeToSearchTemplateDirective,
    NgLoadingTextTemplateDirective,
    NgTagTemplateDirective,
} from '@ng-select/ng-select';
import {UnsubscribeComponent} from '../../../../@core/fc-component';

@Directive()
export class ForwardNgSelectRefs extends UnsubscribeComponent implements AfterViewInit {
    @ViewChild(NgSelectComponent) ngSelect: NgSelectComponent;

    // NgSelect templates
    @ContentChild(NgOptionTemplateDirective, {read: TemplateRef}) optionTemplate: TemplateRef<any>;
    @ContentChild(NgOptgroupTemplateDirective, {read: TemplateRef}) optgroupTemplate: TemplateRef<any>;
    @ContentChild(NgLabelTemplateDirective, {read: TemplateRef}) labelTemplate: TemplateRef<any>;
    @ContentChild(NgMultiLabelTemplateDirective, {read: TemplateRef}) multiLabelTemplate: TemplateRef<any>;
    @ContentChild(NgHeaderTemplateDirective, {read: TemplateRef}) headerTemplate: TemplateRef<any>;
    @ContentChild(NgFooterTemplateDirective, {read: TemplateRef}) footerTemplate: TemplateRef<any>;
    @ContentChild(NgNotFoundTemplateDirective, {read: TemplateRef}) notFoundTemplate: TemplateRef<any>;
    @ContentChild(NgTypeToSearchTemplateDirective, {read: TemplateRef}) typeToSearchTemplate: TemplateRef<any>;
    @ContentChild(NgLoadingTextTemplateDirective, {read: TemplateRef}) loadingTextTemplate: TemplateRef<any>;
    @ContentChild(NgTagTemplateDirective, {read: TemplateRef}) tagTemplate: TemplateRef<any>;

    private _forwardDirectives() {
        if (this.ngSelect) {
            if (this.optionTemplate) this.ngSelect.optionTemplate = this.optionTemplate;
            if (this.optgroupTemplate) this.ngSelect.optgroupTemplate = this.optgroupTemplate;
            if (this.labelTemplate) this.ngSelect.labelTemplate = this.labelTemplate;
            if (this.multiLabelTemplate) this.ngSelect.multiLabelTemplate = this.multiLabelTemplate;
            if (this.headerTemplate) this.ngSelect.headerTemplate = this.headerTemplate;
            if (this.footerTemplate) this.ngSelect.footerTemplate = this.footerTemplate;
            if (this.notFoundTemplate) this.ngSelect.notFoundTemplate = this.notFoundTemplate;
            if (this.typeToSearchTemplate) this.ngSelect.typeToSearchTemplate = this.typeToSearchTemplate;
            if (this.loadingTextTemplate) this.ngSelect.loadingTextTemplate = this.loadingTextTemplate;
            if (this.tagTemplate) this.ngSelect.tagTemplate = this.tagTemplate;
        }
    }

    ngAfterViewInit() {
        this._forwardDirectives();
    }
}
